/**
 * @param {Date | string} date
 * @param {string} locales
 * @param {Intl.DateTimeFormatOptions} options
 * @returns
 */
export const dateFormat = (date, locales = 'en', options) =>
  new Intl.DateTimeFormat(locales, options).format(new Date(date))

/**
 * @param {number} amount
 * @param {string} locales
 * @param {string} currency
 * @returns
 */
export const currencyFormat = (amount, locales = 'en-US', currency = 'USD') =>
  new Intl.NumberFormat(locales, {
    style: 'currency',
    currency
  }).format(amount)

/**
 *
 * @param {Object[]} list
 * @param {string} locales
 * @param {Intl.ListFormatOptions} options
 * @returns
 */
export const joinList = (list, locales = 'en', options) =>
  new Intl.ListFormat(locales, options).format(list)

/**
 * @param {Number} number
 * @param {string} locales
 * @param {Intl.NumberFormatOptions} options
 * @returns
 */
export const numberFormat = (number, locales = 'en-US', options) =>
  new Intl.NumberFormat(locales, options).format(number)
