import React from 'react'
import styled from 'styled-components'

/**
 * @param {Object} params
 * @param {'light' | 'dark' | 'semiLight'} [params.type]
 * @param {string} [params.className]
 * @param {React.ReactChildren} [params.children]
 * @returns
 */
const Card = ({ className, type, children }) => {
  return (
    <CardStyled className={className} type={type}>
      {children}
    </CardStyled>
  )
}

const CardStyled = styled.div`
  border-radius: 10px;
  min-width: 170px;
  height: 74px;

  width: 170px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  &[type='light'] {
    width: 170px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 14px;
  }
  &[type='dark'] {
    width: 170px;
    box-shadow: 0 16px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }
  &[type='semiLight'] {
    width: 170px;
    box-shadow: 50px 50px 100px 0 rgba(0, 0, 0, 0.23);
    border-radius: 10px;
  }
`

export default Card
