import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const LoadingAnimation = ({ dotsNumber = 3, ...props }) => {
  const ContainerVariants = {
    initial: {
      transition: {
        staggerChildren: 0.2
      }
    },
    animate: {
      transition: {
        staggerChildren: 0.2
      }
    }
  }

  const DotVariants = {
    initial: {
      y: '0%'
    },
    animate: {
      y: '100%'
    }
  }

  return (
    <LoadingAnimationStyled
      variants={ContainerVariants}
      initial='initial'
      animate='animate'
      {...props}
    >
      {Array(dotsNumber)
        .fill()
        .map((_, index) => (
          <motion.span
            key={`dot-${index}`}
            variants={DotVariants}
            transition={{
              ease: 'easeInOut',
              duration: 0.5,
              repeat: Infinity,
              repeatType: 'reverse'
            }}
          />
        ))}
    </LoadingAnimationStyled>
  )
}

const LoadingAnimationStyled = styled(motion.div)`
  margin: auto;
  max-width: 250px;
  height: 1.5rem;
  display: flex;
  justify-content: space-around;

  span {
    background-color: ${({ theme }) =>
      theme.colors.primary.main.backgroundColor};

    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50px;
  }
`

export default LoadingAnimation
