import styled, { css, withTheme } from 'styled-components'

const StyledButton = styled.button`
  cursor: pointer;
  font-weight: bold;
  border-radius: 20px;
  -webkit-tap-highlight-color: transparent;

  ${({ theme, buttonType = 'normal', device = 'desktop', size = 'small' }) => {
    const buttonTheme = theme.buttons[buttonType]

    return css`
      border: 2px transparent solid;
      ${buttonTheme.default};
      ${theme.buttons.size[device][size]};

      &:hover {
        ${buttonTheme.hover};
      }
      &:active {
        ${buttonTheme.click};
      }
      &:disabled {
        ${buttonTheme.disabled};
        cursor: not-allowed;
      }
    `
  }}
`

export default withTheme(StyledButton)
