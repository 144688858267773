import styled, { withTheme } from 'styled-components'

const Input = styled.input`
  padding: 5px 0;
  font-size: 14px;

  outline: none;
  border-width: 0 0 2px;

  ${({ theme }) => theme.inputs.light};

  &:placeholder-shown {
    border-width: 0 0 1px;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: #231f20;
    border-style: dotted;
    background-color: transparent;
  }
`

const Label = styled.label`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.grayShades[100].color};
`

const Error = styled.div`
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary.main.backgroundColor};

  .icon {
    color: ${({ theme }) => theme.colors.primary.main.backgroundColor};
  }
`

export const StyledLabel = withTheme(Label)
export const StyledInput = withTheme(Input)
export const StyledSpan = withTheme(Error)
