import { motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { StyledLabel, StyledTextarea } from './text-area-styled'

const MotionTextarea = StyledTextarea.withComponent(motion.textarea)

const TextareaAnimated = ({ scaleFocus = 1.02, ...props }) => {
  const scaleOnFocus = props.disabled ? 1 : scaleFocus
  return <MotionTextarea whileFocus={{ scale: scaleOnFocus }} {...props} />
}

/**
 * Textarea styled with animation.
 * * This component receives the properties which are diffined into StyledInput
 * @param {Object} props
 * @param {string} props.label
 * @param {string} [props.className]
 * @param {string} [props.error]
 */

const Textarea = ({ className, label, error, onChange, ...props }) => {
  const [valueLength, setValueLength] = useState(props.value?.length || 0)
  const uniqueId = nanoid()
  props.maxLength = props.maxLength || 300
  props.rows = props.rows || 3

  const onHandleChange = (e) => {
    setValueLength(e.target.value?.length || 0)
    onChange && onChange(e)
  }

  return (
    <TextareaStyled className={className} error={Boolean(error)}>
      <StyledLabel htmlFor={uniqueId}>{label}</StyledLabel>
      <TextareaAnimated onChange={onHandleChange} {...props} id={uniqueId} />
      <div className='length'>
        <span className='length-label'>
          {valueLength} / {props.maxLength}
        </span>
      </div>
    </TextareaStyled>
  )
}

const TextareaStyled = styled.div`
  min-width: 300px;

  textarea {
    resize: none;
    width: 100%;
    ${({ theme, error }) =>
      error &&
      css`
        border-color: ${theme.colors.primary.main.backgroundColor};
      `}
  }

  .length {
    height: 21px;
    position: relative;

    span.length-label {
      position: absolute;
      right: 0;
      font-size: 10pt;
      ${({ theme, error }) =>
        error &&
        css`
          color: ${theme.colors.primary.main.backgroundColor};
        `}
    }
  }
`

export default Textarea
