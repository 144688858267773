import React from 'react'
import styled from 'styled-components'
import Card from '../card'

const Modal = (props) => {
  const { className, cardType = 'light', children } = props
  return (
    <ModalStyled className={className}>
      <Card className='card-modal' type={cardType}>
        {children}
      </Card>
    </ModalStyled>
  )
}

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgb(81, 81, 81, 0.9);

  .card-modal {
    position: absolute;
    min-width: 300px;
    max-width: 600px;
    min-height: 300px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ theme }) => theme.colors.grayShades[100]};
  }
`

export default Modal
