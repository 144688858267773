import { Icon } from '@keoworld/gbl-ui-kit'
import React, { useState } from 'react'
import styled from 'styled-components'

const Stepper = ({ length = 1, current = 0, previous, next, setStep }) => {
  return (
    <StepperStyled>
      <button onClick={() => previous && previous()}>
        <Icon name='chevron_left' type='round' />
      </button>
      {Array(length)
        .fill()
        .map((_, i) => {
          return (
            <div
              className='step-dot'
              key={`dot-${i}`}
              active={String(current === i)}
              onClick={() => setStep && setStep(i)}
            />
          )
        })}
      <button onClick={() => next && next()}>
        <Icon name='chevron_right' type='round' />
      </button>
    </StepperStyled>
  )
}

export const useStepperController = (length) => {
  const [currentStep, setCurrentStep] = useState(0)

  const previous = () => {
    setCurrentStep((((currentStep - 1) % length) + length) % length)
  }

  const next = () => {
    setCurrentStep((currentStep + 1) % length)
  }

  return { previous, next, currentStep, setCurrentStep }
}

const StepperStyled = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    border-radius: 50px;
    padding: 0;
    width: 34px;
    height: 34px;
    background-color: transparent;
    color: black;
    border: 2px solid black;
  }

  .step-dot {
    cursor: pointer;
    min-width: 8px;
    min-height: 8px;
    border-radius: 50px;
    margin: auto 1rem;
    background-color: ${({ theme }) =>
      theme.buttons.grayButton.default.backgroundColor};
    &[active='true'] {
      background-color: black;
    }
  }
`

export default Stepper
