import React from 'react'
import styled from 'styled-components'
import Icon from '../icon'

const ALERT_ICONS = {
  error: 'highlight_off',
  warning: 'warning',
  info: 'info'
}

/**
 *
 * @param {Object} props
 * @param {Function} props.onClose
 * @param {string} props.title
 * @param {string} props.label
 * @param {'error'|'warning'|'info'} [props.type]
 */
const AlertMessage = ({ onClose, type = 'info', title, label, ...props }) => {
  return (
    <AlertMessageStyled type={type} {...props}>
      <div className='icon-container'>
        <Icon name={ALERT_ICONS[type]} />
      </div>
      <div className='content'>
        <h3>{title}</h3>
        <p>{label}</p>
      </div>
      <button className='close-container' onClick={() => onClose && onClose()}>
        <Icon name='cancel' />
      </button>
    </AlertMessageStyled>
  )
}

const AlertMessageStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  min-width: 400px;
  background-color: #fff;
  margin: auto;

  -webkit-box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
  -moz-box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
  box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);

  border-radius: 8px;

  .icon-container {
    ${({ type, theme }) => theme.alertTheme[type]};
    display: flex;
    padding: 10px;
    width: 50px;
    border-radius: 8px 0px 0px 8px;
    align-items: center;

    span {
      font-size: 32px;
    }
  }

  .content {
    padding: 1em;
    width: 100%;
  }

  button.close-container {
    cursor: pointer;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 0 10px;
  }
`

export default AlertMessage
