import { motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../icon'
import { StyledInput, StyledLabel, StyledSpan } from './styled-input'

const MotionInput = StyledInput.withComponent(motion.input)

const InputAnimated = ({ scaleFocus = 1.02, ...props }) => {
  const scaleOnFocus = props.disabled ? 1 : scaleFocus
  return <MotionInput whileFocus={{ scale: scaleOnFocus }} {...props} />
}

/**
 * Input styled with animation.
 * * This component receives the properties which are defined into StyledInput
 * @param {Object} props
 * @param {string} props.label
 * @param {string} [props.className]
 * @param {string} [props.error]
 * @param {React.ReactElement} [props.icon]
 */

const Input = ({ className, label, error, icon, ...props }) => {
  const uniqueId = nanoid()
  return (
    <InputStyle className={className} error={Boolean(error)}>
      <StyledLabel htmlFor={uniqueId}>{label}</StyledLabel>
      <div className='input' icon={`${Boolean(icon)}`}>
        <InputAnimated {...props} id={uniqueId} />
        {icon}
      </div>
      {error && (
        <StyledSpan>
          {error} <Icon name='warning' />
        </StyledSpan>
      )}
    </InputStyle>
  )
}

const InputStyle = styled.div`
  min-width: 300px;

  div.input {
    width: 100%;

    input {
      width: 100%;
      ${({ theme, error }) =>
        error &&
        css`
          border-color: ${theme.colors.primary.main.backgroundColor};
        `}
    }

    &[icon='true'] {
      position: relative;
    }

    .icon {
      position: absolute;
      margin-left: -1.5rem;

      ${({ theme, error }) =>
        css`
          color: ${error
            ? theme.colors.primary.main.backgroundColor
            : theme.colors.primary.dark.backgroundColor};
        `}
    }
  }
`

export default Input
