import { motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../icon'
import { StyledSelect, StyledLabel, StyledSpan } from './styled-select'

const MotionSelect = StyledSelect.withComponent(motion.select)

const SelectAnimated = ({ scaleFocus = 1.02, ...props }) => {
  const scaleOnFocus = props.disabled ? 1 : scaleFocus
  return <MotionSelect whileFocus={{ scale: scaleOnFocus }} {...props} />
}

/**
 * Select styled with animation.
 * * This component receives the properties which are diffined into StyledSelect
 * @param {Object} props
 * @param {string} props.label
 * @param {string} [props.className]
 * @param {string} [props.error]
 */

const Select = ({ className, label, error, children, ...props }) => {
  const uniqueId = nanoid()
  return (
    <SelectStyled className={className} error={Boolean(error)}>
      <StyledLabel htmlFor={uniqueId}>{label}</StyledLabel>
      <SelectAnimated {...props} id={uniqueId} error={error}>
        {children}
      </SelectAnimated>
      {error && (
        <StyledSpan>
          {error} <Icon name='warning' />
        </StyledSpan>
      )}
    </SelectStyled>
  )
}

const SelectStyled = styled.div`
  min-width: 300px;

  select {
    width: 100%;
    ${({ theme, error }) =>
      error &&
      css`
        border-color: ${theme.colors.primary.main.backgroundColor};
      `}
  }
`

export default Select
