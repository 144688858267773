const THEME = {
  alertTheme: {
    error: {
      backgroundColor: '#FFAEB6',
      color: '#FFFFFF'
    },
    warning: {
      backgroundColor: '#FFFED4',
      color: '#FFFFFF'
    },
    info: {
      backgroundColor: '#D9F5FF',
      color: '#FFFFFF'
    }
  },
  colors: {
    primary: {
      dark: { backgroundColor: '#2F021A', color: '#FFFFFF' },
      main: { backgroundColor: '#FF0045', color: '#FFFFFF' },
      light: { backgroundColor: '#00575B', color: '#FFFFFF' }
    },
    secondary: {
      dark: { backgroundColor: '#751644', color: '#FFFFFF' },
      main: { backgroundColor: '#05B8BC', color: '#FFFFFF' },
      light: { backgroundColor: '#AE0277', color: '#FFFFFF' },
      lighter: { backgroundColor: '#3EDEDD', color: '#FFFFFF' }
    },
    otherOptions: {
      dark: { backgroundColor: '#0056EC', color: '#FFFFFF' },
      main: { backgroundColor: '#FFB100', color: '#FFFFFF' },
      light: { backgroundColor: '#00D477', color: '#FFFFFF' }
    },
    grayShades: {
      800: { backgroundColor: '#515151', color: '#FFFFFF' },
      700: { backgroundColor: '#9B9B9B', color: '#FFFFFF' },
      600: { backgroundColor: '#BCBCBC', color: '#FFFFFF' },
      500: { backgroundColor: '#D8D8D8', color: '#515151' },
      400: { backgroundColor: '#DDDDDD', color: '#515151' },
      300: { backgroundColor: '#EFEFEF', color: '#515151' },
      200: { backgroundColor: '#FAFAFA', color: '#515151' },
      100: { backgroundColor: '#FFFFFF', color: '#515151' }
    }
  },
  buttons: {
    size: {
      desktop: {
        small: { width: '155px', height: '40px' },
        medium: { width: '195px', height: '40px' },
        large: { width: '300px', height: '40px' },
        action: { width: '56px', height: '56px', borderRadius: '50px' }
      },
      tablet: {
        small: { width: '155px', height: '40px' },
        medium: { width: '195px', height: '40px' },
        large: { width: '300px', height: '40px' },
        action: { width: '56px', height: '56px', borderRadius: '50px' }
      },
      mobile: {
        small: { width: '150px', height: '30px' },
        medium: { width: '175px', height: '40px' },
        large: { width: '300px', height: '40px' },
        extraLarge: { width: '330px', height: '40px' },
        action: { width: '56px', height: '56px', borderRadius: '50px' }
      },
      mobileLight: {
        small: { width: '140px', height: '30px' },
        medium: { width: '175px', height: '30px' },
        large: { width: '324px', height: '35px' },
        action: { width: '44px', height: '44px', borderRadius: '50px' }
      }
    },
    normal: {
      default: { backgroundColor: '#FF0045', color: '#FFFFFF' },
      hover: { backgroundColor: '#EC0040', color: '#FFFFFF' },
      click: { backgroundColor: '#D7003A', color: '#FFFFFF' },
      disabled: { backgroundColor: '#FF0045', opacity: 0.7, color: '#FFFFFF' }
    },
    outline: {
      default: {
        backgroundColor: '#FFFFFF',
        color: '#FF0045',
        border: '2px solid #FF0045'
      },
      hover: {
        backgroundColor: 'rgb(255, 0, 69, 0.7)',
        color: '#FFFFFF',
        border: '2px solid #FF0045'
      },
      click: {
        backgroundColor: 'rgb(176, 14, 58, 0.7)',
        color: '#B00E3A',
        border: '2px solid #B00E3A'
      },
      disabled: {
        backgroundColor: '#FFFFFF',
        color: 'rgb(255, 0, 69, 0.7)',
        border: '2px solid rgb(255, 0, 69, 0.5)'
      }
    },
    lighter: {
      default: { backgroundColor: '#3EDEDD', color: '#2F021A' },
      hover: { backgroundColor: '#35C7C6', color: '#2F021A' },
      click: { backgroundColor: '#2BB1B0', color: '#2F021A' },
      disabled: { backgroundColor: '#3EDEDD', opacity: 0.5, color: '#2F021A' }
    },
    light: {
      default: { backgroundColor: '#00575B', color: '#FFFFFF' },
      hover: { backgroundColor: '#002F31', color: '#FFFFFF' },
      click: { backgroundColor: '#002022', color: '#FFFFFF' },
      disabled: { backgroundColor: '#00575B', opacity: 0.5, color: '#FFFFFF' }
    },
    dark: {
      default: { backgroundColor: '#2F021A', color: '#FFFFFF' },
      hover: { backgroundColor: '#2F021A', color: '#FFFFFF' },
      click: { backgroundColor: '#002022', color: '#FFFFFF' },
      disabled: { backgroundColor: '#00575B', opacity: 0.5, color: '#FFFFFF' }
    },
    grayButton: {
      default: { backgroundColor: '#515151', color: '#FFFFFF' },
      hover: { backgroundColor: '#3A3A3A', color: '#FFFFFF' },
      click: { backgroundColor: '#1C1C1C', color: '#FFFFFF' },
      disabled: { backgroundColor: '#515151', opacity: 0.5, color: '#FFFFFF' }
    },
    outlineDark: {
      default: {
        backgroundColor: 'transparent',
        border: '2px solid #2F021A',
        color: '#2F021A'
      },
      hover: {
        backgroundColor: 'rgb(174, 2, 119, 0.15)',
        border: '2px solid #AE0277',
        color: '#AE0277'
      },
      click: {
        backgroundColor: '#AE0277',
        color: '#FFFFFF'
      },
      disabled: {
        backgroundColor: 'transparent',
        border: '2px solid #2F021A',
        color: '#2F021A',
        opacity: 0.5
      }
    },
    otherOptions: {
      default: {
        backgroundColor: 'transparent',
        border: '2px solid #0056EC',
        color: '#0056EC'
      },
      hover: {
        backgroundColor: 'rgb(0, 86, 236, 0.15)',
        border: '2px solid #0056EC',
        color: '#0056EC'
      },
      click: {
        backgroundColor: '#0056EC',
        color: '#FFFFFF'
      },
      disabled: {
        backgroundColor: 'transparent',
        border: '2px solid #0056EC',
        color: '#0056EC',
        opacity: 0.5
      }
    },
    green: {
      default: { backgroundColor: '#00575B', color: '#FFFFFF' },
      hover: { backgroundColor: '#003639', color: '#FFFFFF' },
      click: { backgroundColor: '#003B3E', color: '#FFFFFF' },
      disabled: { backgroundColor: '#00575B', opacity: 0.5, color: '#FFFFFF' }
    }
  },
  inputs: {
    light: {
      color: '#000000',
      borderColor: '#2F021A'
    },
    dark: {
      color: '#FFFFFF',
      borderColor: 'rgb(255,255,255,12)'
    }
  }
}

export default THEME
